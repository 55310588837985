<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item" style="width: 200px">
          <a-select :options="cateList" v-model="queryParams.cateId" @change="search(1)" allowClear style="flex: 1" placeholder="选择分类"></a-select>
        </div>
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.industryName"
              placeholder="名称"
              allowClear
          />
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <!--        <a-button style="margin-left: 8px" @click="reset">重置</a-button>-->
      </div>
      <a-button type="primary" @click="add" style="margin-left:20px;" icon="plus">
        新增
      </a-button>
    </div>
    <div>
      <a-table
          ref="TableInfo"
          size="small"
          :bordered="true"
          :columns="columns"
          rowKey="id"
          :dataSource="dataSource"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
          :scroll="{ x: 1000 }"
      >
        <template slot="status" slot-scope="text, record">
          <a-tag :color="record.status | dictName('validStatus', 'color')" style="cursor: pointer">
            {{ record.status| dictName("validStatus") }}
          </a-tag>
        </template>
        <template slot="cateName" slot-scope="text, record">
          <a-tag color="green" style="cursor: pointer">
            {{ record.cateName }}
          </a-tag>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-button type="primary" icon="edit" size="small" @click="modify(record)">
            修改
          </a-button>
          <a-button type="danger" icon="delete" size="small" style="margin-left:10px" @click="deleteOneRow(record)">
            删除
          </a-button>
        </template>
      </a-table>
    </div>
    <modify-modal ref="modifyModal" @success="editSuccess"/>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";
import modifyModal from "./modifySysIndustryModal";

export default {
  name: "SysIndustryList",
  mixins: [ListMixin],
  components: {modifyModal},
  data() {
    return {
      listGetUrl: "sysIndustry/list",
      listMethod: "get",
      cateList: []
    };
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order
        },

        {
          title: "行业分类",
          width: 200,
          dataIndex: "cateName",
          align: "center",
          scopedSlots: {customRender: "cateName"},
        },
        {
          title: "行业名称",
          align: "center",
          width: 250,
          dataIndex: "industryName",
        },
        {
          title: "状态",
          width: 80,
          dataIndex: "status",
          align: "center",
          sorter: true,
          scopedSlots: {customRender: "status"},
          sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order
        },

        {
          title: "排序",
          width: 80,
          align: "center",
          dataIndex: "sortNumber",
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'sortNumber' && sortedInfo.order
        },
        {
          title: "操作",
          width: 200,
          align: "center",
          dataIndex: "operation",
          scopedSlots: {customRender: "operation"},
        },
        {}
      ];
    },
  },

  created() {
    this.$postJson('/category/cateList', {pageSize: 100, type: 6}).then(res => {
      this.cateList = ((res.data && res.data.records) || []).map(v => {
        return {
          value: v.cateId,
          label: v.cateName
        }
      })
    })
  },
  mounted() {
    this.search(1);
  },
  methods: {
    editSuccess() {
      this.search();
    },
    add() {
      this.$refs.modifyModal.showModal(undefined, 'new');
    },
    modify(record) {
      this.$refs.modifyModal.showModal(record, 'update');
    },

    deleteOneRow(record) {
      let that = this;

      this.$confirm({
        title: "删除确认",
        content: "您确定要删除【" + record.industryName + "】吗？！",
        centered: true,
        onOk() {
          that.$post("sysIndustry/del", {id: record.id}).then(() => {
            that.$message.success("删除成功");
            that.search();
          });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
